import { graphql } from "gatsby"
import React from "react"
import { Helmet } from "react-helmet"
import { Page } from "../../components/elements"
import { Template } from "./Template"

export default function Main({
    data,
    data: {
        project: {
            frontmatter: { id, title },
        },
    },
}) {
    return (
        <Page title={id} description={title}>
            <Helmet>
                <style type="text/css">{`
                @media only screen and (min-width: 600px) {
                    .project-image{
                        max-width:fit-content;
                    }
                }
                @media only screen and (max-width: 600px) {
                    .project-image{
                        text-align:center;
                    }
                }
                `}</style>
            </Helmet>
            <Template data={data} />
        </Page>
    )
}

export const pageQuery = graphql`
    query ($id: String!) {
        project: markdownRemark(frontmatter: { id: { eq: $id } }) {
            ...ProjectFields
        }
        members: allMarkdownRemark(
            filter: { fileAbsolutePath: { regex: "//members//" } }
        ) {
            edges {
                node {
                    ...MemberFields
                }
            }
        }
    }
`
