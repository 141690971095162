import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { removeLocalPath } from "../../cms/previews/tools/removeLocalPath"
import {
    Components,
    DefaultHeader,
    ModalX,
    PageCenter,
    ProjectFields,
    Slider,
} from "../../components/elements"
import { RequestProjectDetailsForm } from "../../components/forms"
import { Markdown } from "../../components/tools/Markdown"

export function Template({
    data: {
        project: {
            frontmatter: fm,
            frontmatter: {
                id,
                title,
                carousel,
                value_to_sponsors,
                components,
                featuredImage,
            } = {},
        } = {},
        members: { edges: memArr } = {},
    },
}) {
    const image = getImage(featuredImage)

    if (components) {
        var components_and_pdf = components.map(({ type, tab, ...props }) => {
            const pdf = {
                title: "Download PDF",
                publicURL: `https://storage.googleapis.com/egipublic/I_Abstracts/${fm.abstract}`,
            }
            if (type == "tabs") return { ...props, type, tab: tab?.concat(pdf) }
            return { type, tab, ...props }
        })
    }
    return (
        <PageCenter>
            <DefaultHeader title={title} type="small" />
            <Row>
                <Col className="project-image">
                    {image ? (
                        <GatsbyImage image={image} alt="Project Thumbnail" />
                    ) : (
                        <img
                            src={removeLocalPath(featuredImage)}
                            alt="featuredImage"
                            style={{ maxWidth: "250px" }}
                        />
                    )}
                    <div className="mt-3">
                        <ModalX
                            title="Send request for more information about this project."
                            button="Request Details"
                        >
                            <RequestProjectDetailsForm id={id} />
                        </ModalX>
                    </div>
                </Col>
                <Col style={{ maxWidth: "480px" }} className="px-4">
                    <ProjectFields fm={fm} members={memArr} />
                </Col>
                {carousel ? (
                    <Col>
                        <Slider items={carousel} />
                    </Col>
                ) : null}
            </Row>
            <div className="my-4 ">
                {value_to_sponsors ? (
                    <div className="py-4 mb-5 border-bottom">
                        <h5 className="pb-4 mb-5 border-bottom">
                            Value to Sponsors:
                        </h5>
                        <Markdown className="px-4">
                            {value_to_sponsors}
                        </Markdown>
                    </div>
                ) : null}
                {!components ? null : (
                    <Components
                        components={components_and_pdf}
                        tab_type="horizontal"
                    />
                )}
            </div>
        </PageCenter>
    )
}
